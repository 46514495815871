<template>
  <div class="mt-3">
    <h4> <b>Versión actual:</b> {{ version }}</h4>
  </div>
</template>

<script>
import Version from '@version'

export default {
  data() {
    return {
      version: Version.version,
    }
  },
}
</script>
